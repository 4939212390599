/* ---------------------------------------------------------------------- */
/*  Icons
/* ---------------------------------------------------------------------- */
.fa-round {
    border: 0.1em solid #eee;
    border-radius: 100%;
    padding: 0.2em 0.25em 0.15em;
}
.the-icons {
  list-style: none;
  margin: 20px 0;
  padding: 0;
  .fa-hover {  
    border-radius: 4px;
    display: block;
    height: 32px;
    line-height: 32px;
    padding-left: 10px;
    color: #8B91A0;
    cursor: pointer;
    @include transition(font-size, .2s, ease 0s);
     &:hover {
      font-size: 26px;
      .fa {
        font-size: 26px; 
      }
     }    
    .fa {
      display: inline-block;
      font-size: 14px;
      margin-right: 10px;
      text-align: right;
      width: 32px;
    }  
  }
}