/* ---------------------------------------------------------------------- */
/*  Basic Elements & Classes
/* ---------------------------------------------------------------------- */
html, body {
  width: 100%;
  overflow-x: hidden;
}
body {
  color: $text-color;
  direction: ltr;
  font-family: $font-family-default;
  font-size: $font-size-base;
  padding: 0;
  margin: 0;
  background: #ffffff;
  height: 100%;
  &.bg_style_1 {
    background: url("../images/bg.png") rgba(0, 0, 0, 0) !important;
  }
  &.bg_style_2 {
    background: url("../images/bg_2.png") rgba(0, 0, 0, 0) !important;
  }
  &.bg_style_3 {
    background: url("../images/bg_3.png") rgba(0, 0, 0, 0) !important;
  }
  &.bg_style_4 {
    background: url("../images/bg_4.png") rgba(0, 0, 0, 0) !important;
  }
  &.bg_style_5 {
    background: url("../images/bg_5.png") rgba(0, 0, 0, 0) !important;
  }
}
::-moz-selection {
background: $blue;
text-shadow: none;
color: $white;
}
::selection {
background: $blue;
text-shadow: none;
color: $white;
}

a, a:focus, a:hover, a:active {
  outline: 0 !important;
  text-decoration: none !important;
}
a {
  color: $link-color;
  
  &:hover, &:focus, &:active {
    color: darken($link-color, 20%);
  }
}
h1, h2, h3, h4 {
  font-family: $font-family-primary;
  font-weight: 300;
  .text-bold &, &.text-bold, .text-bold {
     font-weight: 600;
  }
  
}
.main-wrapper {
  position: relative;
  > .inner-transform  {
    -ms-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.ajax-white-backdrop {
  background-color: rgba($white,0.5);
  display: none;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1049;
}
.ajax-white-backdrop:before {
  background: #000;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  top: 60px;
  left: 50%;
  margin-left: -20px;
  content: "";
  position: absolute;
}
.ajax-white-backdrop:after {
  content: "\f110";
  font-family: FontAwesome;
  width: 40px;
  height: 40px;
  line-height: 40px;
  top: 60px;
  color: $white;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
