/* ---------------------------------------------------------------------- */
/*  Boxed Layout
/* ---------------------------------------------------------------------- */
.layout-boxed > .main-wrapper, .layout-boxed .navbar, .layout-boxed.footer-fixed .footer {
  margin-left: auto !important;
  margin-right: auto !important;
  border-left-width: 0;
  border-left-style: solid;
  border-right-width: 0;
  border-right-style: solid;
}
