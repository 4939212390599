/* ---------------------------------------------------------------------- */
/* Tabs
/* ---------------------------------------------------------------------- */

.nav-tabs {
    margin-bottom: 0;
    padding: 0;
    > li {
      margin-bottom: -3px;
      text-align: center;
      &.dropdown {
        &.active a{
          cursor: pointer;
        }
        .dropdown-menu {
          > .active{
            a, a:hover, a:focus {
              background-color: $lightGrey; 
              color: $white;
            }
          }  
        }
      }
      > a {
        border: 0 none;
        border-radius: 0;
        color: #8E9AA2;
        min-width: 70px;
        padding: 15px 20px;
      }
      &.active {
        a, a:hover, a:focus {
          border-width: 0;
        }
      }
    }
    > .active {
      a, a:hover, a:focus {
        border: 0 none;
        @include box-shadow(1px 1px 2px rgba($black, 0.1));
        color: #505458;
      }
    }
}
.panel-tabs, .tabbable {
  background-color: #E6E8E8;
  padding: 1px;
  margin-bottom: 15px;
}
.panel-tabs {
  padding: 0;
  .panel-heading {
    background: #E6E8E8;
  }
  .panel-body {
    padding: 0;
  } 
  .tabbable {
    margin: 0;
    padding: 0;
  }
  .nav-tabs {
    border: medium none;
    float: right;
    margin-top: -49px;
    > li > a{
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
  }
} 
.tab-content {
    background-color: #FFFFFF;
    position: relative;
}
.tab-content > .tab-pane, .pill-content > .pill-pane {
    display: none !important;
    padding: 15px;
}
.tab-content > .active, .pill-content > .active {
    display: block !important;
    padding: 19px;
}
.tabs-left {
  &.tabbable{
    background-color: none;
    padding: 0;  
  }
  .tab-content {
    border-top: 1px solid #E6E8E8;
    border-right: 1px solid #E6E8E8;
    border-bottom: 1px solid #E6E8E8;
    overflow: hidden;
  }
  > .nav-tabs {
    border: none;
    float: left;
    margin-bottom: 0;
    top: auto; 
    > li {
      float: none;
      text-align: left;
      > a, a:focus, a:hover {
        border-color: #E6E8E8;
        margin: 0 -1px 0 0;      
      }
      &.active {
        > a, a:focus, a:hover {
          border-color: #E6E8E8;
          border-right: none;
          border-left: 1px solid #E6E8E8;
          border-top-width: 1px;
          margin: 0 -1px;      
        }
      }
    }
  }
}
.tabs-right {
  &.tabbable{
    background-color: none;
    padding: 0;  
  }
  .tab-content {
    border-top: 1px solid #E6E8E8;
    border-left: 1px solid #E6E8E8;
    border-bottom: 1px solid #E6E8E8;
    overflow: hidden;
  }
  > .nav-tabs {
    border: none;
    float: right;
    margin-bottom: 0;
    top: auto; 
    > li {
      text-align: left;
      float: none;
      > a, a:focus, a:hover {
        border-color: #E6E8E8;
        margin: 0 0 0 -1px;      
      }
      &.active {
        > a, a:focus, a:hover {
          border-color: #E6E8E8;
          border-right: 1px solid #E6E8E8 !important;
          border-left: none;
          border-top-width: 1px;
          margin: 0 -1px;      
        }
      }
    }
  }
}
.tabs-below > .nav-tabs {
  border-bottom-width: 0;
  border-color: #E6E8E8;
  margin-bottom: 0;
  margin-top: 0;
  top: auto;
  > li {
    margin-bottom: 0;
    > a, a:focus, a:hover {
      border-color: #E6E8E8;
    }
     &.active {
       > a, a:focus, a:hover {
        border-bottom: none;
        border-color: transparent #E6E8E8 #E6E8E8;
        margin-top: 0;        
       }
     }
  }
}

.partition-dark {
  .nav-tabs {
    border: none;
    > li {
      margin-bottom: 0;
      &.active {
        > a, > a:hover, a:focus {
          background-color: $black;
          border: none;
          color: $white;          
        }
        > a:before, a:after {
            border-color: rgba(0, 0, 0, 0);
            border-style: solid;
            display: block;
            height: 0;
            position: absolute;
            width: 0;
        }
        > a:after {
          content: " ";
          height: 0;
          position: absolute;
          width: 0;
          border: 5px solid transparent;
          border-top-color: $black;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          z-index: 1;
        }   
      }
      > a {
        border: none;
        color: $lightGrey;
        padding: 10px 15px;
        @include border-radius(0);     
      }
      > a:hover, a:focus {
          background-color: $black;
      }
    }
  }
  .tab-content {
    z-index: 0;
    color: $black;
  }
} 
