/* ---------------------------------------------------------------------- */
/*  Maps
/* ---------------------------------------------------------------------- */
.map {
  display: block;
  height: 350px;
  margin: 0 auto;
}
.map.large {
  height: 500px;
}