/* ---------------------------------------------------------------------- */
/*  Pagination
/* ---------------------------------------------------------------------- */
.pagination > li > a, .pagination > li > span {
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination.squared {
  border-radius: 0 !important;
}
.pagination.squared li:first-child a, .pagination.squared li:first-child > span {
  border-bottom-left-radius: 0px !important;
  border-left-width: 1px;
  border-top-left-radius: 0px !important;
}
.pagination.squared li:last-child > a, .pagination.squared li:last-child > span {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.pagination.pagination-purple {
  li {
    a {
      background: #F5F4F9;
      border: none !important;
      color: #333333;
      display: inline-block;
      margin-right: 1px;      
    }
    &:hover {
      background: #ECEAF3;
      color: #222222;
      cursor: pointer;      
    }
    .pagination & {
      a {
        background: #F5F4F9;
        border: none !important;
        color: #333333;
        display: inline-block;
        margin-right: 1px;      
      }
      &:hover {
        background: #ECEAF3;
        color: #222222;
        cursor: pointer;      
      }      
    }
  }
}


.pagination.pagination-purple li:first-child a, .pagination-purple .pagination li:first-child a {
  border: none !important;
}

.pagination.pagination-purple li.disabled a, .pagination-purple .pagination li.disabled a {
  color: #AAAAAA !important;
}
.pagination.pagination-purple li.disabled a:hover, .pagination-purple .pagination li.disabled a:hover, .pagination.pagination-purple li.disabled a:focus, .pagination-purple .pagination li.disabled a:focus {
  background: #F5F4F9;
  cursor: default !important;
}
.pagination.pagination-purple li.active a, .pagination-purple .pagination li.active a {
  background: #57517B !important;
  border: none !important;
  color: #FFFFFF !important;
  cursor: default !important;
  opacity: 1;
}
.pagination-purple [class^="fa-"], .pagination-purple [class*=" fa-"] {
  color: #57517B;
}
.pagination-purple li.disabled [class^="fa-"], .pagination-purple li.disabled [class*=" fa-"] {
  opacity: 0.5;
}
.pagination-blue{
  li {
    a {
      background: desaturate(lighten($blue,45%),15%);
      border: none !important;
      color: $text-color;
      display: inline-block;
      margin-right: 1px;
      &:hover {
        background: desaturate(lighten($blue,40%),15%);
        color: darken($text-color, 30%);
        cursor: pointer;        
      }   
    }
    &:first-child {
      a {
        border: none !important;
      }
    }
    &.disabled {
      a {
        background: desaturate(lighten($blue,45%),15%);
        @include opacity(0.3); 
        &:hover, &:focus {
          background: lighten($blue, 45%);
          @include opacity(0.3); 
          cursor: default !important;          
        }
      }
    }
    &.active {
      a {
        background: $blue !important;
        border: none !important;
        color: $white !important;
        cursor: default !important;
        @include opacity(1);
      }    
    }
    [class^="fa-"], [class*=" fa-"] {
      color: $blue;
    }
  }
}
.pagination-green{
  li {
    a {
      background: desaturate(lighten($green,50%),15%);
      border: none !important;
      color: $text-color;
      display: inline-block;
      margin-right: 1px;
      &:hover {
        background: desaturate(lighten($green,45%),15%);
        color: darken($text-color, 30%);
        cursor: pointer;        
      }   
    }
    &:first-child {
      a {
        border: none !important;
      }
    }
    &.disabled {
      a {
        background: desaturate(lighten($green,50%),15%);
        @include opacity(0.3); 
        &:hover, &:focus {
          background: lighten($green, 45%);
          @include opacity(0.3); 
          cursor: default !important;          
        }
      }
    }
    &.active {
      a {
        background: $green !important;
        border: none !important;
        color: $white !important;
        cursor: default !important;
        @include opacity(1);
      }    
    }
    [class^="fa-"], [class*=" fa-"] {
      color: $green;
    }
  }
}
.pagination-red{
  li {
    a {
      background: lighten($red,30%);
      border: none !important;
      color: $text-color;
      display: inline-block;
      margin-right: 1px;
      &:hover {
        background: lighten($red,25%);
        color: darken($text-color, 30%);
        cursor: pointer;        
      }   
    }
    &:first-child {
      a {
        border: none !important;
      }
    }
    &.disabled {
      a {
        background: lighten($red,30%);
        @include opacity(0.3); 
        &:hover, &:focus {
          background: lighten($red, 45%);
          @include opacity(0.3); 
          cursor: default !important;          
        }
      }
    }
    &.active {
      a {
        background: $red !important;
        border: none !important;
        color: $white !important;
        cursor: default !important;
        @include opacity(1);
      }    
    }
    [class^="fa-"], [class*=" fa-"] {
      color: $red;
    }
  }
}