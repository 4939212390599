.progress.progress-xs {
  height: 7px;
}
.progress.progress-sm {
  height: 12px;
}
.progress.progress-lg {
  height: 20px;
}
.progress.transparent-black {
    background-color: rgba(0, 0, 0, 0.2);
}