/* ---------------------------------------------------------------------- */
/*  Tables
/* ---------------------------------------------------------------------- */
.table > thead > tr > th {
  border-bottom: 1px solid lighten($grey, 30%);
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    border-top: 1px solid lighten($grey, 30%);
}
.table-hover > tbody > tr:hover > td, .table-hover > tbody > tr:hover > th {
  background-color: lighten($grey, 40%);
}
.table > tbody > tr.active > td, .table > tbody > tr.active > th {
  background-color: lighten($grey, 38%) !important;
  color: $text-color !important;
}
.table > tbody > tr.active:hover > td, .table > tbody > tr.active:hover > th {
  background-color: lighten($grey, 36%) !important;
  color: $text-color !important;
}
th.center, td.center {
  text-align: center;
}
td.center .dropdown-menu {
  text-align: left;
}
td.small-width {
  width: 15%;  
}
.table thead tr {
  color: #707070;
  font-weight: normal;
}
.table.table-bordered thead tr th {
  vertical-align: middle;
}
.table thead > tr > th, .table tbody > tr > th, .table tfoot > tr > th, .table thead > tr > td, .table tbody > tr > td, .table tfoot > tr > td {

  vertical-align: middle;
}

.editable-element {
  border-bottom: 1px dashed #0088CC;
  text-decoration: none;
  opacity: 0.5;
  display: inline;
}