/* ---------------------------------------------------------------------- */
/*  Timeline
/* ---------------------------------------------------------------------- */
div.timeline {
  margin: 0 100px 0 0;
  overflow: hidden;
  position: relative;
  .columns {
    margin: 0;
    padding: 0;
    list-style: none;
    > li:nth-child(2n+1) {
      float: left;
      width: 50%;
      clear: left;
      .timeline_element {
        float: right;
        left: 10%;
        margin-right: 30px;
        left: 0;
        opacity: 1;
        &:before {
          right: -27px;
          top: 15px;
        }
        &:after {
          right: -35px;
          top: 10px;
        }
      }
    }
    > li:nth-child(2n+2) {
      float: right;
      margin-top: 20px;
      width: 50%;
      clear: right;
      .timeline_element {
        float: left;
        margin-left: 30px;
        right: 10%;
        opacity: 1;
        right: 0;
        &:before {
          left: -27px;
          top: 15px;
        }
        &:after {
          left: -35px;
          top: 10px;
        }
      }
    }          
  }
  .date_separator {
    clear: both;
    height: 60px;
    position: relative;
    text-align: center;
    span {
      border-radius: 5px;
      height: 30px;
      line-height: 30px;
      margin-top: -16px;
      position: absolute;
      top: -200%;
      width: 200px;
      top: 50%;
      left: 50%;
      margin-left: -100px;
      border: 1px solid $darkGrey;
      background-color: $grey;
      color: $white;
    }
  }
  .spine {
    border-radius: 2px;
    position: absolute;
    top: 0;
    width: 4px;
    left: 50%;
    margin-left: -2px;
    bottom: 0;
    background-color: lighten($lightGrey,20%);
  }
  .column_center{
    .timeline_element {
      margin: 20px auto;
      opacity: 1;
      &:before, &:after {
        display: none;
      }     
    }    
  }
}

.timeline_element {
  border-radius: 5px;
  clear: both;
  margin: 10px 0;
  padding: 20px;
  opacity: 0;
  position: relative;
  transition: all 0.2s linear 0s;
  min-width: 66.6667%;
  text-shadow: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  &.partition-white {
    border: 1px solid lighten($lightGrey, 20%);
    &:hover {
      border: 1px solid lighten($lightGrey, 15%);  
    }
    &:after {
      background-color: $white;
    }
    &:hover:after {
      background-color: darken($lightGrey, 20%);
      border: 1px solid $white;
    }
  }
  &.partition-green {
    border: none;
    color: $white;
    &:hover {
      border: none;
    }
    &:after {
      background-color: $green;
    }
    &:hover:after {
      background-color: $white;
      border: 1px solid $green;
    }
  }
  &.partition-orange {
    border: none;
    color: $white;
    &:hover {
      border: none;
    }
    &:after {
      background-color: $orange;
    }
    &:hover:after {
      background-color: $white;
      border: 1px solid $orange;
    }
  }
  &.partition-blue {
    border: none;
    color: $white;
    &:hover {
      border: none;
    }
    &:after {
      background-color: $blue;
    }
    &:hover:after {
      background-color: $white;
      border: 1px solid $blue;
    }
  }
  &.partition-red {
    border: none;
    color: $white;
    &:hover {
      border: none;
    }
    &:after {
      background-color: $red;
    }
    &:hover:after {
      background-color: $white;
      border: 1px solid $red;
    }
  }
  &.partition-azure {
    border: none;
    color: $white;
    &:hover {
      border: none;
    }
    &:after {
      background-color: $azure;
    }
    &:hover:after {
      background-color: $white;
      border: 1px solid $azure;
    }
  }
  &.partition-purple {
    border: none;
    color: $white;
    &:hover {
      border: none;
    }
    &:after {
      background-color: $purple;
    }
    &:hover:after {
      background-color: $white;
      border: 1px solid $purple;
    }
  }
  &:hover {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  }
  &:before {
    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 26px;
    border-top: 1px dashed #CCCCCC;
  }
  &:after {
    border-radius: 100%;
    content: "";
    display: block;
    height: 10px;
    position: absolute;
    width: 10px;
    background-color: #BBBBBB;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  }
  &:hover:after {
    z-index: 100;
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    z-index: 100;
  }
  .timeline_title {
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    padding-top: 10px;
  }
  .timeline_date {
    display: block;
    .day{
      font-size: 52px;
      letter-spacing: -2px;
    }
  }
  .timeline_content {
    padding-top: 10px;
  }
  .readmore {
    padding: 10px;
    text-align: right;
  }
}
.timeline-scrubber {
    padding: 8px 0 8px 1px;
    top: 60px;
    right: 0;
    width: 100px;
    z-index: 1;
    list-style: none;  
    position: absolute; 
    li {
      margin-bottom: 1px;
      &:nth-last-child(2){
        a {
          border-color: lighten($lightGrey, 10%);
          color: lighten($lightGrey, 10%);
        }
      }
      &:last-child {
        a {
          border-color: lighten($lightGrey, 15%);
          color: lighten($lightGrey, 15%);
        }
      }
    }
    a {
      border-left: 5px solid $lightGrey;
      color: $lightGrey;
      display: block;
      font-weight: normal;
      outline: medium none;
      padding: 4px 0 4px 6px; 
      &:hover {
        border-color: darken($lightGrey, 20%) !important;
        color: darken($lightGrey, 20%) !important;
      }    
    }
    .selected > a {
      border-left-color: $darkGrey !important;
      color: $darkGrey !important;
      font-weight: bold !important;
    }
}

/* ie8 fixes */
.ie8 div.timeline_element:after {
  display: none;
}
.ie8 div.timeline_element:before {
  display: none;
}
/**/
