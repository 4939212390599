/* ---------------------------------------------------------------------- */
/*  Main Container
/* ---------------------------------------------------------------------- */
.main-container {
  margin-top: $header-height !important;
  margin-left: 0; //$slideBar-width;
  position: relative;
  .sidebar-close & {
    margin-left: 0;
  }
  .footer-fixed & {
    margin-bottom: $footer-height;
  }
  .right-sidebar-open & {
    left: -$slideBar-width;
  }
  .main-content {
    position: relative !important;
    left: 0 !important;
  }
  .container {
    max-width: none !important;
    width: auto !important;
    > .row,
    #ajax-content > .row {
      padding: 0 0 0 10px;
    }
  }
  .header-default & {
    margin-top: 0 !important;
  }
}

.toolbar {
  background-color: #ffffff;
  border-bottom: 1px solid #d5d5d5;
  height: 80px;
  position: relative;
  max-height: 80px;
  z-index: 10;
  .toolbar-tools {
    @include opacity(1);
    left: 0;
    position: relative;
    @include transition(all, 0.3s, ease-in);
    .nav {
      margin: 0;
      .dropdown-light {
        &:after {
          right: 40px;
          left: auto;
          top: -10px;
        }
        &:before {
          right: 39px;
          left: auto;
          top: -11px;
        }
      }
    }
    .badge {
      background-color: #f35958;
      color: #ffffff;
      padding: 4px 6px;
      position: absolute;
      left: 25px;
      top: 10px;
      font-size: 10px;
    }
    .view-all {
      text-align: center;
    }
    .drop-down-wrapper {
      ul {
        list-style: none;
        margin: 0;
        padding: 5px 5px 0 5px;
      }
    }
  }
  .page-header {
    border-bottom: medium none;
    margin: 0;
    padding: 0;
    h1 {
      color: #666666;
      font-weight: 200;
      letter-spacing: -1px;
      margin: 10px 0 0 0;
      padding: 0;
    }
    small {
      color: #888888;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      display: block;
      margin-top: 5px;
    }
  }
  .navbar-right {
    > li {
      float: left;
      > a {
        @include border-radius(3px);
        color: #111111;
        font-size: $font-size-extra-small;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 31px;
        margin-right: 3px;
        margin-top: 2px;
        @include opacity(0.9);
        padding: 8px 0 7px 0;
        position: relative;
        text-align: center;
        width: 90px;
        i {
          display: block;
          margin-top: 12px;
          color: #111111;
          font-size: 16px;
        }
      }
    }
    .dropdown-menu {
      &:before {
        top: -11px;
        right: 39px;
      }
      &:after {
        top: -10px;
        right: 40px;
      }
      &.dropdown-subview {
        .fa-stack {
          height: 1.9em;
          line-height: 1.9em;
          vertical-align: top;
          margin-right: 5px;
        }
      }
      &.dropdown-messages {
        width: 300px !important;
        .drop-down-wrapper {
          ul {
            > li {
              a {
                @include border-radius(3px);
                color: $text-color;
                display: block;
                margin-bottom: 5px;
                padding: 10px;
                color: #333333;
                border-bottom: none;
                background-color: #f8f8f8;
                &.unread {
                  background-color: #eeeeee;
                }
                &:hover,
                &:focus,
                &:active {
                  background-color: #f5f5f5;
                }
                .thread-image {
                  float: left;
                  height: 50px;
                  margin-right: 8px;
                  width: 50px;
                }
                .author {
                  display: block;
                  font-size: $font-size-small;
                  font-weight: 600;
                  line-height: normal;
                }
                .preview {
                  display: block;
                  font-size: $font-size-extra-small;
                  line-height: normal;
                  color: #888888;
                }
                .time {
                  display: block;
                  float: right;
                  font-size: $font-size-extra-small;
                  font-style: italic;
                  font-weight: 600;
                  color: #888888;
                }
              }
            }
          }
        }
      }
    }
  }
}

.search-box {
  @include border-radius(0);
  width: 300px;
  line-height: normal;
  right: 0;
  top: auto;
  left: auto;
  bottom: -58px;
  border: 1px solid rgba(17, 17, 17, 0.15);
  .arrow {
    left: auto !important;
    margin-left: 0 !important;
    right: 35px;
    top: -11px;
  }
  input {
    background-color: #f8f8f8;
    border-color: #f8f8f8;
    color: #808080;
    @include border-radius(0);
    @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));
    &:focus {
      border-color: darken(#f8f8f8, 2%);
      background-color: darken(#f8f8f8, 2%);
      @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));
    }
  }
}

.breadcrumb {
  background: none;
  @include border-radius(0);
  font-size: $font-size-small;
  padding: 8px 0;
  margin-bottom: 10px;
  margin-top: 10px;
  > li + li:before {
    color: #777777;
  }
  > .active {
    color: #777777;
    font-weight: 600;
  }
}
